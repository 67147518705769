import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { COR_NAV_PANEL_TEXT_DARK } from 'corporate-ui/cor-constants';
import { corContentstackImageLinkResolver } from 'corporate-utils/cor-contentstack-image-link-resolver';
import { useOutsideClick } from 'hooks/use-outside-click';
import 'corporate-components/cor-region-selector/cor-region-selector.scss';
import { IconMenuArrow } from 'components/icons/icon-menu-arrow';
import { useContentstackSearchTags } from 'hooks/use-contentstack-search-tags';
import { useLocalStorage } from 'hooks/use-local-storage';
import { CorContentStackLanguages, CorContentStackLanguagesMap } from 'constants/cor-locale.enum';
import { isInternationalSite } from 'utils/get-locale-params';
import { isPreference_EU } from 'utils/get-geolocation-preference';
import { ICSUtilityNavData } from 'components/layout/cor-header/cor-header.interface';
import { clearCachedContentstackContent } from 'utils/clear-cached-contentstack-content';

export interface IMenuDropdownProps {
  direction?: 'down' | 'up';
  className?: string;
}

export const CorRegionSelector: FC<IMenuDropdownProps> = ({ direction = 'down', className }) => {
  let selected: any = null;
  const panelRef = useRef<HTMLDivElement>(null);
  const [localeGlobal, setValue] = useLocalStorage('locale', null);
  const [, setImpersonationModeLocale] = useLocalStorage('impersonation_mode_locale', null);
  const [preference, setPreferenceValue] = useLocalStorage('preference', null);
  const [opened, setOpened] = useState(false);
  const innerRef = useRef(null);
  const handleDropdownClick = useCallback(() => {
    setOpened(!opened);
  }, [opened]);
  const handleItemClick = (href) => {
    const localeCode = CorContentStackLanguagesMap.get(href);
    clearCachedContentstackContent();

    setOpened(false);
    setValue(localeCode);
    setImpersonationModeLocale(localeCode);

    if (isPreference_EU(preference) && localeCode === CorContentStackLanguages.ENGLISH_EUROPE) {
      setPreferenceValue((prev) => {
        return {
          ...prev,
          [href]: null,
        };
      });
    }
  };

  useEffect(() => {
    if (panelRef.current && opened && direction === 'down') {
      const offset = 5;
      const rect = panelRef.current.getBoundingClientRect();
      const maxHeight = Math.floor(document.documentElement.clientHeight - rect.top - offset);

      panelRef.current.style.maxHeight = `${maxHeight}px`;
    }
  }, [opened, direction]);

  useOutsideClick(innerRef, opened, handleDropdownClick, true);

  const data: ICSUtilityNavData =
    useContentstackSearchTags([
      {
        contentstackParams: 'region-links',
        contentTypeName: 'sites_selector',
        ...(isInternationalSite(localeGlobal) && { locale: localeGlobal }),
      },
    ])[0] || {};

  if (isEmpty(data)) {
    return null;
  }

  const regional_sites: any[] = data?.regional_site || [];
  const locale: string = data.locale;
  const listData = regional_sites.map(({ country_code, site_icon, site_url }: any) => {
    const { url, link_url, open_in_a_new_tab, image_alt_text } = {
      ...corContentstackImageLinkResolver(site_icon),
    };

    if (country_code === (localeGlobal || locale)) {
      selected = {
        label: country_code.split('-')[0],
        site_icon: url,
      };
    }
    return {
      country_code,
      site_url,
      label: site_url.title.split(':'),
      site_icon: {
        url,
        link_url,
        open_in_a_new_tab,
        image_alt_text,
      },
    };
  });

  return (
    <div className={classNames('region-link', className)} ref={innerRef}>
      <button className={classNames('region-link__button', { 'is-active': opened })} onClick={handleDropdownClick}>
        <img className="region-link__icon--selected" src={selected?.site_icon} alt={selected?.label} />
        <span>{selected?.label}</span>
        <IconMenuArrow dir={opened ? 'down' : 'up'} color={COR_NAV_PANEL_TEXT_DARK} height={10} />
      </button>

      {opened && (
        <div ref={panelRef} className={`region-link__panel region-link__panel--${direction}`}>
          <ul className={'region-link__list'}>
            {listData.map((item, index) => (
              <li onClick={() => handleItemClick(item.site_url.href)} key={index}>
                <a href={item.site_url.href} rel="noopener noreferrer">
                  <img className="region-link__icon" src={item.site_icon.url} alt={item.site_icon.image_alt_text} />
                  <div className="link-text">
                    <span className="link-text__country">{item.label[0]}</span>
                    {item.label[1] && <span className="link-text__language"> - {item.label[1]}</span>}
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
