import { FC, PropsWithChildren, useCallback } from 'react';
import { useGlobalModalState } from 'hooks/use-global-modal-state';
import { IManageQuoteModalProps, ManageQuoteModal } from 'components/modals/manage-quote-modal';
import {
  IInternalUserViewQuoteModalProps,
  InternalUserViewQuoteModal,
} from 'components/modals/internal-user-view-quote-modal';
import {
  CancelQuoteWithReasonModal,
  ICancelQuoteWithReasonModalProps,
} from 'components/modals/cancel-quote-with-reason-modal';
import {
  ISuccessErrorMessageModalProps,
  SuccessErrorMessageModal,
} from 'components/modals/success-error-message-modal';
import { IProductZoomGalleryModalProps, ProductZoomGalleryModal } from 'components/modals/product-zoom-gallery-modal';
import {
  ViewMySpecialOrderQuoteModal,
  IViewMySpecialOrderQuoteModalProps,
} from 'components/modals/view-my-special-order-quote-modal';
import { CancelQuoteRequestModal, ICancelQuoteRequestModalProps } from 'components/modals/cancel-quote-request-modal';
import { IQuoteOrderSummaryModalProps, QuoteOrderSummaryModal } from 'components/modals/quote-order-summary-modal';
import { IReorderSuccessModalProps, ReorderSuccessModal } from 'components/modals/reorder-success-modal';
import {
  AllItemsAddedToCartResultMessageModal,
  IAllItemsAddedToCartResultMessageModalProps,
} from 'components/modals/all-items-added-to-cart-result-message-modal';
import { IRequestQuoteModalProps, RequestQuoteModal } from 'components/modals/request-quote-modal';
import { INotificationsListModalProps, NotificationsListModal } from 'components/modals/notifications-list-modal';
import {
  AddSpecialOrderItemToCartErrorModal,
  IAddSpecialOrderItemToCartErrorModalProps,
} from 'components/modals/add-special-order-item-to-cart-error-modal';
import { RequestQuoteSuccessMessageModal } from 'components/modals/request-quote-success-message-modal';
import { IModalProps } from 'components/modals/common/modal';
import {
  AddExceededItemToCartModal,
  IAddExceededItemToCartModalProps,
} from 'components/modals/add-exceeded-item-to-cart-modal';
import {
  AddItemsToShoppingListModal,
  IAddItemsToShoppingListModalProps,
} from 'components/modals/add-items-to-shopping-list-modal';
import {
  CreateShoppingListSuccessMessageModal,
  ICreateShoppingListSuccessMessageModalProps,
} from 'components/modals/create-shopping-list-success-message-modal';
import {
  EditLocationsUserInfoModal,
  IEditLocationsUserInfoModalProps,
} from 'components/modals/edit-locations-user-info-modal';
import {
  EditUserPersonalInfoModal,
  IEditUserPersonalInfoModalProps,
} from 'components/modals/edit-user-personal-info-modal';
import { IInviteNewUserModalProps, InviteNewUserModal } from 'components/modals/invite-new-user-modal';
import { ChangePasswordModal, IChangePasswordModalProps } from 'components/modals/change-password-modal';
import {
  EditUserInfoSuccessMessageModal,
  IEditUserInfoSuccessMessageModalProps,
} from 'components/modals/edit-user-info-success-message-modal';
import {
  IRemoveUserConfirmationModalProps,
  RemoveUserConfirmationModal,
} from 'components/modals/remove-user-confirmation-modal';
import { IVideoModalProps, VideoModal } from 'components/modals/video-modal';
import {
  CancelOrderConfirmModal,
  ICancelOrderConfirmModalProps,
} from 'components/modals/cancel-order-confirm-modal/cancel-order-confirm-modal';
import {
  CsrEditUserInfoMobileModal,
  ICsrEditUserInfoMobileModalProps,
} from 'components/modals/csr-edit-user-info-mobile-modal';
import {
  DiscontinuedItemsWithReplacementsModal,
  IDiscontinuedItemsWithReplacementsModalProps,
} from 'components/modals/discontinued-items-with-replacements-modal';
import { ModifyYourOrderModal } from 'components/modals/modify-your-order-modal/modify-your-order-modal';
import { ITimestampModalProps, TimestampModal } from 'components/modals/timestamp-modal';
import { ISelectOptionModalProps, SelectOptionModal } from 'components/modals/select-option-modal';
import { CreateShoppingListModal, ICreateShoppingListModalProps } from 'components/modals/create-shopping-list-modal';
import { ImportShoppingListModal } from 'components/modals/import-shopping-list-modal';
import {
  IQuickAddToShoppingListModalProps,
  QuickAddToShoppingListModal,
} from 'components/modals/quick-add-to-shopping-list-modal';
import {
  ISelectShoppingListActionModalProps,
  SelectShoppingListActionModal,
} from 'components/modals/select-shopping-list-action-modal';
import {
  AddOutOfStockToCartErrorModal,
  IAddOutOfStockToCartErrorModalProps,
} from 'components/modals/add-out-of-stock-to-cart-error-modal';
import { IWhereDidYouFindUsModalProps, WhereDidYouFindUsModal } from 'components/modals/where-did-you-find-us-modal';
import { IProprietaryItemModalProps, ProprietaryItemModal } from 'components/modals/proprietary-item-modal';
import {
  ISortCustomerLocationsOptionsMobileModalProps,
  SortCustomerLocationsOptionsMobileModal,
} from 'components/modals/sort-customer-locations-options-mobile-modal';
import {
  RemoveItemsFromCartConfirmModal,
  IRemoveItemsConfirmationModalProps,
} from 'components/modals/remove-items-from-cart-confirm-modal';
import {
  DeleteShoppingListConfirmModal,
  IDeleteShoppingListConfirmModalProps,
} from 'components/modals/delete-shopping-list-confirm-modal';
import {
  EditShoppingListNameModal,
  IEditShoppingListNameModalProps,
} from 'components/modals/edit-shopping-list-name-modal';
import {
  HowToCreateShoppingListModal,
  IHowToCreateShoppingListModalProps,
} from 'components/modals/how-to-create-shopping-list-modal';
import { LockedAccountMessageModal } from 'components/modals/locked-account-message-modal';
import { IQuickShopModalProps, QuickShopModal } from 'components/modals/quick-shop-modal';
import { ILocationUsersInfoModalProps, LocationUsersInfoModal } from 'components/modals/location-users-info-modal';
import {
  LocationsSelectConfirmationModal,
  ILocationsSelectConfirmationModalProps,
} from 'components/modals/locations-select-confirmation-modal';
import { ISelectLocationUserModalProps, SelectLocationUserModal } from 'components/modals/select-location-user-modal';
import { IGeolocationRedirectModalProps, GeolocationRedirectModal } from 'components/modals/geolocation-redirect-modal';
import { AddPaymentCardModal, IAddPaymentCardModalProps } from 'components/modals/add-credit-card-modal';
import { IRemovePaymentCardModalProps, RemovePaymentCardModal } from 'components/modals/remove-payment-card-modal';
import { AddNewBankAccountModal, IAddNewBankAccountModalProps } from 'components/modals/add-new-bank-account-modal';
import {
  IRemovePaymentBankAccountModalProps,
  RemovePaymentBankAccountModal,
} from 'components/modals/remove-payment-bank-account-modal/remove-payment-bank-account-modal';
import {
  IPaymentMethodsErrorModalProps,
  PaymentMethodsErrorModal,
} from 'components/modals/payment-methods-error-modal';
import {
  ISchedulePaymentModalProps,
  SchedulePaymentModal,
} from 'components/modals/schedule-payment-modal/schedule-payment-modal';
import { IShortpayReasonsModalProps, ShortpayReasonsModal } from 'components/modals/shortpay-reasons-modal';
import {
  AddNewBankAccountEftPadModal,
  IAddNewBankAccountEftPadModalProps,
} from 'components/modals/add-new-bank-account-modal-eft-pad';
import {
  CancelScheduledPaymentModal,
  ICancelScheduledPaymentModalProps,
} from 'components/modals/cancel-scheduled-payment-modal/cancel-scheduled-payment-modal';
import { IMakeDepositProps, MakeDepositModal } from 'components/modals/make-deposit-modal';
import { AutopayModal, IAutopayModalProps } from 'components/modals/autopay-modal';
import { AutopayOptOutModal } from 'components/modals/autopay-opt-out';
import {
  IPromotionCodeDetailsModalProps,
  PromotionCodeDetailsModal,
} from 'components/modals/promotion-code-details-modal';

export const useHideGlobalModalHandler = () => {
  const { hideGlobalModal } = useGlobalModalState();

  return hideGlobalModal;
};

// Use this hook standalone with using generic type and modal component
// or create own hook on top of it saving modal component in closure and defining prop types for future reuse
// returns show (wrapper function over show function from global modal context) function that optionally accepts additional props for modal component.

export const useGlobalModal = <P>(
  ModalComponent: FC<P>,
  modalComponentProps?: Partial<PropsWithChildren<P>>
): ((props?: Partial<PropsWithChildren<P>>) => void) => {
  const { showGlobalModal } = useGlobalModalState();

  return useCallback(
    (props?) => {
      showGlobalModal({
        ModalComponent,
        modalComponentProps: {
          ...modalComponentProps,
          ...props,
        },
      });
    },
    [showGlobalModal, ModalComponent, modalComponentProps]
  );
};

// General
export const useSuccessErrorMessageModal = (modalComponentProps: PropsWithChildren<ISuccessErrorMessageModalProps>) =>
  useGlobalModal<ISuccessErrorMessageModalProps>(SuccessErrorMessageModal, modalComponentProps);

export const useVideoModal = (modalComponentProps?: IVideoModalProps) =>
  useGlobalModal<IVideoModalProps>(VideoModal, modalComponentProps);

export const useWhereDidYouFindUsModal = (modalComponentProps?: Partial<IWhereDidYouFindUsModalProps>) =>
  useGlobalModal<IWhereDidYouFindUsModalProps>(WhereDidYouFindUsModal, modalComponentProps);

export const useQuickShopModal = (modalComponentProps?: Partial<IQuickShopModalProps>) =>
  useGlobalModal<IQuickShopModalProps>(QuickShopModal, modalComponentProps);

export const useNotificationsListModal = (modalComponentProps: INotificationsListModalProps) =>
  useGlobalModal<INotificationsListModalProps>(NotificationsListModal, modalComponentProps);

// Quotes
export const useManageQuoteModal = (modalComponentProps: IManageQuoteModalProps) =>
  useGlobalModal<IManageQuoteModalProps>(ManageQuoteModal, modalComponentProps);

export const useInternalUserViewQuoteModal = (modalComponentProps: IInternalUserViewQuoteModalProps) =>
  useGlobalModal<IInternalUserViewQuoteModalProps>(InternalUserViewQuoteModal, modalComponentProps);

export const useCancelQuoteWithReasonModal = (modalComponentProps: ICancelQuoteWithReasonModalProps) =>
  useGlobalModal<ICancelQuoteWithReasonModalProps>(CancelQuoteWithReasonModal, modalComponentProps);

export const useViewMySpecialOrderQuoteModal = (modalComponentProps: IViewMySpecialOrderQuoteModalProps) =>
  useGlobalModal<IViewMySpecialOrderQuoteModalProps>(ViewMySpecialOrderQuoteModal, modalComponentProps);

export const useCancelQuoteRequestModal = (modalComponentProps: ICancelQuoteRequestModalProps) =>
  useGlobalModal<ICancelQuoteRequestModalProps>(CancelQuoteRequestModal, modalComponentProps);

export const useQuoteOrderSummaryModal = (modalComponentProps: IQuoteOrderSummaryModalProps) =>
  useGlobalModal<IQuoteOrderSummaryModalProps>(QuoteOrderSummaryModal, modalComponentProps);

export const useRequestQuoteModal = (modalComponentProps?: IRequestQuoteModalProps) =>
  useGlobalModal<IRequestQuoteModalProps>(RequestQuoteModal, modalComponentProps);

export const useRequestQuoteSuccessMessageModal = (modalComponentProps?: Partial<IModalProps>) =>
  useGlobalModal<IModalProps>(RequestQuoteSuccessMessageModal, modalComponentProps);

// PDP
export const useProductZoomGalleryModal = (modalComponentProps: IProductZoomGalleryModalProps) =>
  useGlobalModal<IProductZoomGalleryModalProps>(ProductZoomGalleryModal, modalComponentProps);

// Reorder
export const useReorderSuccessModal = (modalComponentProps: Partial<IReorderSuccessModalProps>) =>
  useGlobalModal<IReorderSuccessModalProps>(ReorderSuccessModal, modalComponentProps);

export const useAllItemsAddedToCartResultMessageModal = (
  modalComponentProps?: Partial<IAllItemsAddedToCartResultMessageModalProps>
) =>
  useGlobalModal<IAllItemsAddedToCartResultMessageModalProps>(
    AllItemsAddedToCartResultMessageModal,
    modalComponentProps
  );

// Orders
export const useCancelOrderConfirmModal = (modalComponentProps?: Partial<ICancelOrderConfirmModalProps>) =>
  useGlobalModal<ICancelOrderConfirmModalProps>(CancelOrderConfirmModal, modalComponentProps);

export const useModifyYourOrderModal = (modalComponentProps?: Partial<IModalProps>) =>
  useGlobalModal<IModalProps>(ModifyYourOrderModal, modalComponentProps);

export const useTimestampModal = (modalComponentProps?: Partial<ITimestampModalProps>) =>
  useGlobalModal<ITimestampModalProps>(TimestampModal, modalComponentProps);

export const useSelectOptionModal = (modalComponentProps?: Partial<ISelectOptionModalProps>) =>
  useGlobalModal<ISelectOptionModalProps>(SelectOptionModal, modalComponentProps);

export const useProprietaryItemModal = (modalComponentProps?: Partial<IProprietaryItemModalProps>) =>
  useGlobalModal<IProprietaryItemModalProps>(ProprietaryItemModal, modalComponentProps);

// Cart
export const useAddSpecialOrderItemToCartErrorModal = (
  modalComponentProps?: Partial<IAddSpecialOrderItemToCartErrorModalProps>
) =>
  useGlobalModal<IAddSpecialOrderItemToCartErrorModalProps>(AddSpecialOrderItemToCartErrorModal, modalComponentProps);

export const useAddExceededItemToCartModal = (modalComponentProps?: Partial<IAddExceededItemToCartModalProps>) =>
  useGlobalModal<IAddExceededItemToCartModalProps>(AddExceededItemToCartModal, modalComponentProps);

export const useDiscontinuedItemsWithReplacementsModal = (
  modalComponentProps?: Partial<IDiscontinuedItemsWithReplacementsModalProps>
) =>
  useGlobalModal<IDiscontinuedItemsWithReplacementsModalProps>(
    DiscontinuedItemsWithReplacementsModal,
    modalComponentProps
  );

export const useAddOutOfStockToCartErrorModal = (modalComponentProps?: Partial<IAddOutOfStockToCartErrorModalProps>) =>
  useGlobalModal<IAddOutOfStockToCartErrorModalProps>(AddOutOfStockToCartErrorModal, modalComponentProps);

export const useRemoveItemsFromCartConfirmationModal = (
  modalComponentProps?: Partial<IRemoveItemsConfirmationModalProps>
) => useGlobalModal<IRemoveItemsConfirmationModalProps>(RemoveItemsFromCartConfirmModal, modalComponentProps);

export const usePromotionCodeDetailsModal = (modalComponentProps?: Partial<IPromotionCodeDetailsModalProps>) =>
  useGlobalModal<IPromotionCodeDetailsModalProps>(PromotionCodeDetailsModal, modalComponentProps);

// Shopping Lists
export const useAddItemsToShoppingListModal = (modalComponentProps?: Partial<IAddItemsToShoppingListModalProps>) =>
  useGlobalModal<IAddItemsToShoppingListModalProps>(AddItemsToShoppingListModal, modalComponentProps);

export const useCreateShoppingListModal = (modalComponentProps?: Partial<ICreateShoppingListModalProps>) =>
  useGlobalModal<ICreateShoppingListModalProps>(CreateShoppingListModal, modalComponentProps);

export const useImportShoppingListModal = (modalComponentProps?: Partial<IModalProps>) =>
  useGlobalModal<IModalProps>(ImportShoppingListModal, modalComponentProps);

export const useSelectShoppingListActionModal = (modalComponentProps?: Partial<ISelectShoppingListActionModalProps>) =>
  useGlobalModal<ISelectShoppingListActionModalProps>(SelectShoppingListActionModal, modalComponentProps);

export const useCreateShoppingListSuccessMessageModal = (
  modalComponentProps?: Partial<ICreateShoppingListSuccessMessageModalProps>
) =>
  useGlobalModal<ICreateShoppingListSuccessMessageModalProps>(
    CreateShoppingListSuccessMessageModal,
    modalComponentProps
  );

export const useQuickAddToShoppingListModal = (modalComponentProps?: Partial<IQuickAddToShoppingListModalProps>) =>
  useGlobalModal<IQuickAddToShoppingListModalProps>(QuickAddToShoppingListModal, modalComponentProps);

export const useDeleteShoppingListConfirmModal = (
  modalComponentProps?: Partial<IDeleteShoppingListConfirmModalProps>
) => useGlobalModal<IDeleteShoppingListConfirmModalProps>(DeleteShoppingListConfirmModal, modalComponentProps);

export const useEditShoppingListNameModal = (modalComponentProps?: Partial<IEditShoppingListNameModalProps>) =>
  useGlobalModal<IEditShoppingListNameModalProps>(EditShoppingListNameModal, modalComponentProps);

export const useHowToCreateShoppingListModal = (modalComponentProps?: Partial<IHowToCreateShoppingListModalProps>) =>
  useGlobalModal<IHowToCreateShoppingListModalProps>(HowToCreateShoppingListModal, modalComponentProps);

// Users
export const useInviteNewUserModal = (modalComponentProps?: Partial<IInviteNewUserModalProps>) =>
  useGlobalModal<IInviteNewUserModalProps>(InviteNewUserModal, modalComponentProps);

export const useEditLocationsUserInfoModal = (modalComponentProps?: Partial<IEditLocationsUserInfoModalProps>) =>
  useGlobalModal<IEditLocationsUserInfoModalProps>(EditLocationsUserInfoModal, modalComponentProps);

export const useEditUserInfoSuccessMessageModal = (
  modalComponentProps?: Partial<IEditUserInfoSuccessMessageModalProps>
) => useGlobalModal<IEditUserInfoSuccessMessageModalProps>(EditUserInfoSuccessMessageModal, modalComponentProps);

export const useRemoveUserConfirmationModal = (modalComponentProps?: Partial<IRemoveUserConfirmationModalProps>) =>
  useGlobalModal<IRemoveUserConfirmationModalProps>(RemoveUserConfirmationModal, modalComponentProps);

export const useEditUserPersonalInfoModal = (modalComponentProps?: Partial<IEditUserPersonalInfoModalProps>) =>
  useGlobalModal<IEditUserPersonalInfoModalProps>(EditUserPersonalInfoModal, modalComponentProps);

export const useChangePasswordModal = (modalComponentProps?: Partial<IChangePasswordModalProps>) =>
  useGlobalModal<IChangePasswordModalProps>(ChangePasswordModal, modalComponentProps);

export const useLockedAccountMessageModal = () => useGlobalModal<IModalProps>(LockedAccountMessageModal);

export const useLocationUsersInfoModal = (modalComponentProps?: Partial<ILocationUsersInfoModalProps>) =>
  useGlobalModal<ILocationUsersInfoModalProps>(LocationUsersInfoModal, modalComponentProps);

export const useLocationsSelectConfirmationModal = (
  modalComponentProps?: Partial<ILocationsSelectConfirmationModalProps>
) => useGlobalModal<ILocationsSelectConfirmationModalProps>(LocationsSelectConfirmationModal, modalComponentProps);

export const useSelectLocationUserModal = (modalComponentProps?: Partial<ISelectLocationUserModalProps>) =>
  useGlobalModal<ISelectLocationUserModalProps>(SelectLocationUserModal, modalComponentProps);

// Dashboard
export const useCsrEditUserInfoMobileModal = (modalComponentProps?: Partial<ICsrEditUserInfoMobileModalProps>) =>
  useGlobalModal<ICsrEditUserInfoMobileModalProps>(CsrEditUserInfoMobileModal, modalComponentProps);

export const useSortCustomerLocationsOptionsMobileModal = (
  modalComponentProps?: Partial<ISortCustomerLocationsOptionsMobileModalProps>
) =>
  useGlobalModal<ISortCustomerLocationsOptionsMobileModalProps>(
    SortCustomerLocationsOptionsMobileModal,
    modalComponentProps
  );

//Geolocation
export const useGeolocationRedirectModal = (modalComponentProps?: Partial<IGeolocationRedirectModalProps>) =>
  useGlobalModal<IGeolocationRedirectModalProps>(GeolocationRedirectModal, modalComponentProps);

//payments
export const useAddPaymentCardModal = (modalComponentProps?: Partial<IAddPaymentCardModalProps>) =>
  useGlobalModal<IAddPaymentCardModalProps>(AddPaymentCardModal, modalComponentProps);

export const useRemovePaymentCardModal = (modalComponentProps?: Partial<IRemovePaymentCardModalProps>) =>
  useGlobalModal<IRemovePaymentCardModalProps>(RemovePaymentCardModal, modalComponentProps);

export const useAddNewBankAccountModal = (modalComponentProps?: Partial<IAddNewBankAccountModalProps>) =>
  useGlobalModal<IAddNewBankAccountModalProps>(AddNewBankAccountModal, modalComponentProps);

export const useAddNewBankAccountEftPadModal = (modalComponentProps?: Partial<IAddNewBankAccountModalProps>) =>
  useGlobalModal<IAddNewBankAccountEftPadModalProps>(AddNewBankAccountEftPadModal, modalComponentProps);

export const useRemovePaymentBankAccountModal = (modalComponentProps?: Partial<IRemovePaymentBankAccountModalProps>) =>
  useGlobalModal<IRemovePaymentBankAccountModalProps>(RemovePaymentBankAccountModal, modalComponentProps);

export const usePaymentMethodsErrorModal = (modalComponentProps?: Partial<IPaymentMethodsErrorModalProps>) =>
  useGlobalModal<IPaymentMethodsErrorModalProps>(PaymentMethodsErrorModal, modalComponentProps);

export const useSchedulePaymentModal = (modalComponentProps?: Partial<ISchedulePaymentModalProps>) =>
  useGlobalModal<ISchedulePaymentModalProps>(SchedulePaymentModal, modalComponentProps);

export const useShortpayReasonsModal = (modalComponentProps?: Partial<IModalProps>) =>
  useGlobalModal<IShortpayReasonsModalProps>(ShortpayReasonsModal, modalComponentProps);

export const useMakeDepositModal = (modalComponentProps?: Partial<IMakeDepositProps>) =>
  useGlobalModal<IMakeDepositProps>(MakeDepositModal, modalComponentProps);

export const useCancelScheduledPaymentModal = (modalComponentProps?: Partial<ICancelScheduledPaymentModalProps>) =>
  useGlobalModal<ICancelScheduledPaymentModalProps>(CancelScheduledPaymentModal, modalComponentProps);

export const useAutopayModal = (modalComponentProps?: Partial<IAutopayModalProps>) =>
  useGlobalModal<IAutopayModalProps>(AutopayModal, modalComponentProps);

export const useAutopayOptOutModal = (modalComponentProps?: Partial<IModalProps>) =>
  useGlobalModal<IModalProps>(AutopayOptOutModal, modalComponentProps);
