import { FC } from 'react';
import { ContentstackText } from 'components/contentstack';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { CorButton } from 'components/cor-button';

import './promotion-code-details-modal.scss';

export interface IPromotionCodeDetailsModalProps extends IModalProps {
  promoCodeDescription: string;
}

export const PromotionCodeDetailsModal: FC<IPromotionCodeDetailsModalProps> = ({
  promoCodeDescription,
  isOpen,
  onClose,
}) => {
  const contentstackPath = 'modals.0.promotion_code_details_modal.0';

  return (
    <Modal withBackdropClick isOpen={isOpen} onClose={onClose} className="promotion-code-details-modal" size="medium">
      <ModalHeader titleContentstackPath={`${contentstackPath}.header`} />
      <div className="promotion-code-details-modal__body">
        <span>{promoCodeDescription}</span>
      </div>
      <ModalFooter
        className="promotion-code-details-modal__footer"
        hasCancelButton
        contentstackPath={contentstackPath}
        cancelButtonHandler={onClose}
      >
        <CorButton color="primary" className="promotion-code-details-modal__close-button" onClick={onClose}>
          <ContentstackText contentKey={`${contentstackPath}.close_button_label`} />
        </CorButton>
      </ModalFooter>
    </Modal>
  );
};
