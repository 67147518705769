import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useContent } from 'hooks/use-content';
import { useLocalStorage } from 'hooks/use-local-storage';
import { localeAlternatives } from 'constants/locale-lang-alternatives-seo';

export const DocumentHead: FC = () => {
  const { getContentByKey } = useContent();
  const [locale] = useLocalStorage('locale', null);

  return (
    <Helmet htmlAttributes={{ lang: localeAlternatives[locale] ?? locale }}>
      <title>{getContentByKey('title', '')}</title>
      <meta name="description" content={getContentByKey('page_meta_tags.description', '')} />
      <meta name="keywords" content={getContentByKey('page_meta_tags.keywords', '')} />
    </Helmet>
  );
};
