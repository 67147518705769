export const localeAlternatives = {
  'en-eu': 'en',
  'es-la': 'es-mx',
  'en-ce': 'en',
  'en-nd': 'en',
  'en-ap': 'en',
  'nl-be': 'nl',
  'en-gl': 'en',
  'fr-be': 'fr',
};
